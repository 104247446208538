html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.search-list {
  max-height: 300px;
  overflow: auto;
}

.custom-container {
  width: 100%;
}

.icon {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.btn-block {
  display: block;
  width: 100%;
}

.border0 {
  border-width: 0px;
}

.border1 {
  border: 1px solid black;
}

.hide {
  display: none;
}

/* table and its elements styles */

table.dataTable tbody td {
  vertical-align: middle;
}

table.dataTable.table-striped>tbody>tr.odd>* {
  box-shadow: none;
}

div.dataTables_wrapper div.dataTables_info {
  padding: 0;
}

div.dataTables_wrapper div.dataTables_length select {
  padding: 0.4375rem;
}

div.dataTables_filter label,
div.dataTables_length label {
  display: flex;
  align-items: center;
}

div.dataTables_wrapper div.dataTables_length select {
  margin: 0 4px;
}

div.dataTables_filter input {
  display: block;
  width: 100%;
  padding: 0.4375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: inherit;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  appearance: none;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-left: 8px;
}

div.dataTables_paginate .pagination {
  margin: 0;
}

.custom-alert {
  display: flex;
}

.custom-icon {
  width: 36px;
  height: 36px;
}

.stato-richieste hr {
  margin: 20px 0;
}

/*label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}*/