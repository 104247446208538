#rendicontazione #buttons-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

#rendicontazione #buttons-container>div {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin-bottom: 1rem;
}

#rendicontazione #stato {
    font-size: 16px;
}

#rendicontazione #mesi button {
    margin-bottom: 20px;
}

#rendicontazione .radius12 {
    border-radius: 12px;
}

#rendicontazione #mesi button.active {
    background-color: #43646B;
    color: #ffffff;
    cursor: not-allowed;
}

#rendicontazione .changeWeek {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
}

#rendicontazione .changeWeek span {
    padding: .5rem;
}

#rendicontazione .changeWeek-text {
    color: #807E81;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

#rendicontazione .changeWeek-selected {
    border-bottom: solid 2px #43646B;
}

#rendicontazione .changeWeek-number {
    color: #43646B;
    font-weight: 700;
}

.action-icon {
    font-size: 20px !important;
    cursor: pointer;
}

.deleteParent,
.removeAttachment {
    cursor: pointer;
    color: #ff0000;
}

.downloadAttachment {
    cursor: pointer;
    color: #0000ff;
}

.sendEmail {
    cursor: pointer;
}

.validate {
    cursor: pointer;
    color: #008000;
}

.fa.fa-trash.removeAttachment {
    font-size: 20px;
}

/*#tabella-rendicontazione { background-color: #fff; border: solid 2px #43646B; }
#tabella-rendicontazione thead {
    background-color: #9ECCCC;
    color: #807E81;
    border-bottom: solid 2px #43646B;
}
#tabella-rendicontazione thead th { border-bottom: 0px; padding: .5rem; }
#tabella-rendicontazione .products-list { color: #43646B; }*/
#rendicontazione .pagination-month .page-item {
    border-bottom: solid 4px #9ECCCC;
}

#rendicontazione .pagination-month .page-link {
    color: #43646B;
}

#rendicontazione .pagination-month .page-item.active .page-link {
    color: #fff;
    background-color: #9ECCCC;
}

#rendicontazione .day.festivo {
    background-color: #f2f2f1;
}

#rendicontazione .day.corrente {
    background-color: rgba(255, 220, 40, 0.15);
}

#rendicontazione .day-number.festivo {
    color: #c81414;
    font-weight: bold;
}

#rendicontazione .totale-ore-giorno.straordinario {
    color: red;
    font-weight: bold;
}

#rendicontazione .totale-ore-giorno {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
}

#rendicontazione .totale-ore-title {
    font-size: 24px;
}

#rendicontazione #totale-ore {
    font-weight: bold;
    font-size: 28px;
}

#rendicontazione .day.selected {
    background-color: #9ecccc;
}

#rendicontazione .days-week h5 {
    background-color: #43646B;
    border: 1px solid #ddd;
    font-weight: bold;
}

#rendicontazione .day {
    overflow-y: auto;
}

#rendicontazione .nome-rendicontazione,
#rendicontazione .valore-commessa-padre {
    word-wrap: break-word;
}

#rendicontazione #yearsDropdown>.dropdown-menu {
    left: 0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content;
}

.signature-box {
    padding: 20px;
    position: absolute;
    left: calc(50% - 420px);
}

.signature-box #document {
    width: 800px;
    margin: auto;
}

.signature-box .otp {
    width: 270px;
    height: 135px;
    position: relative;
    border: solid 3px red;
    padding: 5px;
    border-radius: 10px;
}

.signature-box .otp.assegnazione,
.signature-box .otp.hrdocuments {
    bottom: 240px;
    left: 3%;
}

.signature-box .otp.nomina {
    bottom: 10625px;
    left: 45%;
}

.signature-box select#channel {
    min-width: 175px;
}

@media (max-width:575px) {
    #rendicontazione .display-4 {
        font-size: 1.5rem;
    }

    #rendicontazione .day h5 {
        background-color: #f8f9fa;
        padding: 3px 5px 5px;
        margin: -8px -8px 8px -8px;
    }

    #rendicontazione .date {
        padding-left: 4px;
    }

    #rendicontazione #yearsDropdown button {
        font-size: 1.5rem;
    }
}

@media (min-width: 576px) {
    #rendicontazione .day {
        height: 14.2857vw;
    }
}